import { useNavigate, useParams } from "react-router-dom";
import AppContainer from "../../../components/AppContainer";
import QuestionFeed from "../components/QuestionFeed";

export default function PostPage() {
  const { postId } = useParams();
  const navigate = useNavigate();

  if (!postId || !postId.length) {
    navigate("/404");
    return <> </>;
  }
  return (
    <AppContainer>
      <QuestionFeed feedType={"single"} postId={postId} />
    </AppContainer>
  );
}
