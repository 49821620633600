import AppContainer from "../../../components/AppContainer";
import QuestionFeed from "../components/QuestionFeed";

type FeedPageProps = { feedType: "new" | "top" };
export default function FeedPage({ feedType }: FeedPageProps) {
  return (
    <AppContainer>
      <QuestionFeed feedType={feedType} />
    </AppContainer>
  );
}
