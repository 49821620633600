import { initializeApp } from "firebase/app";
import "firebase/functions";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { Analytics, getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCsexmwyGFCbvJAIrv0cTgdks4gJW_shO8",
  authDomain: "achewood-chat.firebaseapp.com",
  projectId: "achewood-chat",
  storageBucket: "achewood-chat.appspot.com",
  messagingSenderId: "566782293691",
  appId: "1:566782293691:web:8b791cc55ca19c6df0db9e",
  functions: {
    region: "us-central1",
    httpsCallable: {
      handleChatMessage: "handleChatMessage",
      handleFeedback: "handleFeedback",
    },
  },
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseFunctions = getFunctions(firebaseApp);
let firebaseAnalytics: Analytics | undefined;
const useEmulator = false;
if (useEmulator) {
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
}

const initializeAnalytics = () => {
  if (firebaseAnalytics === undefined) {
    firebaseAnalytics = getAnalytics(firebaseApp);
  }
};

export {
  firebaseApp,
  firebaseFunctions,
  firebaseAnalytics,
  initializeAnalytics,
};
