import React, { ReactNode } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import Div100vh from "react-div-100vh";
import { Link } from "react-router-dom";

interface AppContainerProps {
  children: ReactNode;
}

const AppContainer: React.FC<AppContainerProps> = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Div100vh>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", maxWidth: 640, mx: "auto", overflow: "hidden" }}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 1,
            width: "100%",
            height: isSmallScreen ? "100%" : 640,
          }}
        >
          <Box
            sx={{
              display: "flex",
              boxSizing: "border-box",
              width: "100%",
              height: "48px",
              backgroundColor: "black",
              color: "white",
              px: 1,
              py: 2,
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <Box
              sx={{
                fontFamily: "Baskerville",
                fontWeight: "bold",
                marginRight: "auto",
              }}
            >
              BETA
            </Box>
            <Link
              to="/"
              style={{
                fontFamily: "Lucida Console, Source Code Pro",
                color: "white",
                textDecoration: "underline",
              }}
            >
              Ask Ray
            </Link>
            &nbsp; | &nbsp;
            <Link
              to="/top"
              style={{
                fontFamily: "Lucida Console, Source Code Pro",
                color: "white",
                textDecoration: "underline",
              }}
            >
              Hot
            </Link>
            &nbsp; | &nbsp;
            <Link
              to="/new"
              style={{
                fontFamily: "Lucida Console, Source Code Pro",
                color: "white",
                textDecoration: "underline",
              }}
            >
              New
            </Link>
            &nbsp; | &nbsp;
            <Link
              to="https://www.achewood.com/2005/01/27/title.html"
              style={{
                fontFamily: "Lucida Console, Source Code Pro",
                color: "white",
                textDecoration: "underline",
              }}
              target="_blank"
            >
              Who's Ray?
            </Link>
          </Box>
          {children}
        </Box>
      </Grid>
    </Div100vh>
  );
};

export default AppContainer;
