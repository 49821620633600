import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import rayReadyImage from "../../../assets/rayready.png";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Link } from "react-router-dom";

const takeDownTheSite = false;

type IntroProps = {
  initialName?: string;
  onSubmitName: (name: string) => void;
};
const Intro = ({ initialName, onSubmitName }: IntroProps) => {
  const [name, setName] = useLocalStorage("name", initialName || "");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && name.trim() !== "") {
      handleSubmit();
      event.preventDefault();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSubmit = () => {
    if (name.trim() !== "") {
      onSubmitName(name.trim());
    }
  };

  return (
    <Stack spacing={2}>
      <Stack
        spacing={0}
        paddingLeft={2}
        paddingTop={2}
        direction="row"
        justifyContent="space-between"
      >
        <Stack>
          <Typography
            sx={{
              marginBottom: 0,
              fontFamily: "Lucida Console, Source Code Pro",
              fontSize: "20px",
              fontWeight: 800,
              alignSelf: "flex-start",
            }}
          >
            Welcome to RayBot
          </Typography>
          <Typography
            sx={{
              marginBottom: 2,
              fontFamily: "Lucida Console, Source Code Pro",
              fontSize: "16px",
              fontWeight: 800,
              alignSelf: "flex-start",
            }}
          >
            from New Tradition Labs
          </Typography>
          <Typography
            sx={{
              marginBottom: 2,
              fontFamily: "Lucida Console, Source Code Pro",
              fontSize: "16px",
              alignSelf: "flex-start",
            }}
          >
            Ray is a cartoon cat who spilled his martini into a light socket
            (curiosity) and gained the powers of Artificial Intelligence.
          </Typography>
        </Stack>
        <img
          src={rayReadyImage}
          alt=""
          style={{
            width: 140,
            height: 140,
            alignSelf: "flex-end",
          }}
        />
      </Stack>
      <Divider style={{ margin: 0 }} />

      <Stack paddingX={4} paddingTop={2} spacing={2}>
        {takeDownTheSite ? (
          <Stack paddingX={4} paddingTop={2} spacing={2}>
            <Typography sx={{ fontFamily: "Lucida Console, Source Code Pro" }}>
              RayBot is experiencing extra heavy load currently. These AI tokens
              don't come cheap!
            </Typography>
            <Typography sx={{ fontFamily: "Lucida Console, Source Code Pro" }}>
              Please check back tomorrow to ask RayBot a question. In the
              meantime, check out the{" "}
              <Link to="/top" style={{ color: "black" }}>
                Hot
              </Link>{" "}
              questions.
            </Typography>
          </Stack>
        ) : (
          <>
            <Typography sx={{ fontFamily: "Lucida Console, Source Code Pro" }}>
              One question, one answer. RayBot isn't a chat bot.
            </Typography>
            <Typography sx={{ fontFamily: "Lucida Console, Source Code Pro" }}>
              Please enter a nickname to ask RayBot a question. Your nickname
              will be attached to questions in the “New” and “Hot” pages.
            </Typography>
          </>
        )}
        <Stack direction="column" spacing={2}>
          {!takeDownTheSite && (
            <Stack direction="row" spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "white",
                  border: "2px solid black",
                  borderRadius: 2,
                  px: 1,
                  py: 0.5,
                  minWidth: "200px",
                }}
              >
                <TextField
                  variant="standard"
                  placeholder="Your name"
                  value={name}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  sx={{ mr: 1, fontFamily: "Lucida Console, Source Code Pro" }}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      fontSize: "16px",
                      fontFamily: "Lucida Console, Source Code Pro",
                    },
                  }}
                  maxRows={8}
                />
              </Box>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={name.length === 0}
              >
                Submit
              </Button>
            </Stack>
          )}
          <Box
            sx={{
              position: "absolute",
              bottom: 12,

              fontFamily: "Lucida Console, Source Code Pro",
              fontSize: "12px",
            }}
          >
            <Link
              to="/settings"
              style={{
                textDecorationColor: "#eaeaea",
                textDecoration: "none",
                color: "#000",
              }}
            >
              Settings
            </Link>{" "}
            •{" "}
            <Link
              to="/about"
              style={{
                textDecorationColor: "#eaeaea",
                textDecoration: "none",
                color: "#000",
              }}
            >
              About
            </Link>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Intro;
