import {
  Box,
  Button,
  Checkbox,
  Divider,
  Modal,
  Stack,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import rayReadyImage from "../../../assets/rayready.png";
import useCookie from "react-use-cookie";
import AppContainer from "../../../components/AppContainer";
import { Link } from "react-router-dom";
import TermsOfService from "../../chat/components/TermsOfService";
import { useState } from "react";

export default function SettingsPage() {
  const [consent, setConsent] = useCookie("cookieConsent", "false");
  const [showTerms, setShowTerms] = useState(false);

  return (
    <AppContainer>
      <Stack>
        <Stack
          spacing={0}
          paddingLeft={2}
          paddingTop={2}
          direction="row"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              marginBottom: 2,
              fontFamily: "Lucida Console, Source Code Pro",
              fontSize: "16px",
              alignSelf: "flex-start",
            }}
          >
            There's settings?
            <br />
            (Man, why you even got to do a thing?)
          </Typography>
          <img
            src={rayReadyImage}
            alt=""
            style={{
              width: 140,
              height: 140,
              alignSelf: "flex-end",
            }}
          />
        </Stack>
        <Divider style={{ margin: 0 }} />
        <Stack paddingX={4} paddingY={2} spacing={2}>
          <Stack direction="row" alignItems="center">
            <Box>Consent to analytics cookies</Box>
            <Checkbox
              checked={consent === "true"}
              onChange={(e) => setConsent(e.target.checked ? "true" : "false")}
            />
          </Stack>
          <MuiLink
            sx={{
              // fontFamily: "Lucida Console, Source Code Pro",
              cursor: "pointer",
            }}
            onClick={() => setShowTerms(true)}
          >
            Terms of Service and Privacy Policy
          </MuiLink>
          <Link
            to="/raybot"
            style={{ marginLeft: "auto", marginRight: "auto" }}
          >
            <Button variant="contained">Back to RayBot</Button>
          </Link>
        </Stack>
      </Stack>
      <Modal open={showTerms}>
        <TermsOfService onClose={() => setShowTerms(false)} />
      </Modal>
    </AppContainer>
  );
}
