import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import rayReadyImage from "../../../assets/rayready.png";
import AppContainer from "../../../components/AppContainer";
import styled from "@emotion/styled";

const PaddedTypography = styled(Typography)({
  fontFamily: "Lucida Console, Source Code Pro",
  fontSize: "16px",
  marginBottom: "8px",
});

const StyledList = styled.ul({
  fontFamily: "Lucida Console, Source Code Pro",
  fontSize: "14px",
  marginTop: 0,
});

export default function AboutPage() {
  return (
    <AppContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - 48px)",
        }}
      >
        <Stack
          spacing={0}
          paddingLeft={2}
          paddingTop={2}
          direction="row"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              marginTop: 6,
              marginBottom: 2,
              fontFamily: "Lucida Console, Source Code Pro",
              fontSize: "16px",
              fontWeight: "bold",
              alignSelf: "flex-start",
            }}
          >
            About New Tradition Labs
          </Typography>
          <img
            src={rayReadyImage}
            alt=""
            style={{
              width: 140,
              height: 140,
              alignSelf: "flex-end",
            }}
          />
        </Stack>
        <Divider style={{ margin: 0 }} />
        <Box
          sx={{
            flex: "1 1 90%",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            p: 2,
          }}
        >
          <PaddedTypography>We brought Ray Smuckles to life.</PaddedTypography>
          <PaddedTypography>We are:</PaddedTypography>

          <StyledList style={{}}>
            <li>Ben “Lyle” Porten - CEO</li>
            <li>Chris “Todd” Onstad - President</li>
            <li>David "Téodor" Hall - Technical Lead</li>
            <li>Jason "Roast Beef" Prado - Design Lead</li>
          </StyledList>

          <PaddedTypography>
            Help Ray by reporting technical or content issues to{" "}
            <Link href="mailto:bugreport@raybot.help">
              bugreport@raybot.help
            </Link>
          </PaddedTypography>
          <PaddedTypography>
            Otherwise, we look forward to speaking with you at{" "}
            <Link href="mailto:info@raybot.help">info@raybot.help</Link>
          </PaddedTypography>
          <PaddedTypography
            sx={{
              fontFamily: "Lucida Console, Source Code Pro",
              fontSize: "16px",
            }}
          ></PaddedTypography>
        </Box>
      </Box>
    </AppContainer>
  );
}
