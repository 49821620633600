import { Stack, Typography, Divider, Button, Link, Box } from "@mui/material";
import rayReadyImage from "./rayready.png";

type ConsentProps = {
  onAgree: () => void;
  onClickTerms: () => void;
};
const Consent = ({ onAgree, onClickTerms }: ConsentProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 100px)",
      }}
    >
      <Stack
        spacing={0}
        paddingLeft={2}
        paddingTop={2}
        direction="row"
        justifyContent="space-between"
      >
        <Typography
          sx={{
            marginBottom: 2,
            fontFamily: "Lucida Console, Source Code Pro",
            fontSize: "16px",
            alignSelf: "flex-start",
          }}
        >
          Free Short & Sweet Advice.
          <br />
          (This ain't a chat-bot.)
        </Typography>
        <img
          src={rayReadyImage}
          alt=""
          style={{
            width: 140,
            height: 140,
            alignSelf: "flex-end",
          }}
        />
      </Stack>
      <Divider style={{ margin: 0 }} />
      <Box
        sx={{
          flex: "1 1 90%",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Stack paddingX={4} paddingTop={2} spacing={2}>
          <Typography
            sx={{
              fontFamily: "Lucida Console, Source Code Pro",
              fontSize: "14px",
            }}
          >
            This advice is not intended to replace professional advice. It's an
            AI-driven advice column, let's be serious here.
          </Typography>
          <Typography
            sx={{
              fontFamily: "Lucida Console, Source Code Pro",
              fontSize: "14px",
            }}
          >
            While we strive to ensure this service only vends safe, inoffensive
            advice, the limits of AI technology mean that we cannot guarantee
            that all advice will be safe or inoffensive. You must be 18 or older
            to ask RayBot advice.
          </Typography>
          <Stack direction="column" spacing={2} alignItems="center">
            <Typography sx={{ fontFamily: "Lucida Console, Source Code Pro" }}>
              By clicking 'I Agree' you acknowledge that you have read and agree
              to abide by RayBot's{" "}
              <Link onClick={() => onClickTerms()}>Terms of Service</Link>.
            </Typography>
            <Button
              sx={{ width: "200px", marginLeft: "auto", marginRight: "auto" }}
              variant="contained"
              onClick={() => onAgree()}
            >
              I agree
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Consent;
