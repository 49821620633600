type Feedback = "upvote" | "downvote";
type Report = "report";

function recordFeedback(postId: string, feedback: Feedback | undefined) {
  const feedbackStore = JSON.parse(localStorage.getItem("feedback") || "{}");
  if (feedback) {
    feedbackStore[postId] = feedback;
  } else {
    delete feedbackStore[postId];
  }
  feedbackStore.lastUpdated = Date.now();
  localStorage.setItem("feedback", JSON.stringify(feedbackStore));
}

function getFeedback(postId: string): Feedback | undefined {
  const feedbackStore = JSON.parse(localStorage.getItem("feedback") || "{}");
  return feedbackStore[postId];
}

function recordReport(postId: string, report: Report | undefined) {
  const reportStore = JSON.parse(localStorage.getItem("reports") || "{}");
  if (report) {
    reportStore[postId] = report;
  } else {
    delete reportStore[postId];
  }
  reportStore.lastUpdated = Date.now();
  localStorage.setItem("reports", JSON.stringify(reportStore));
}

function getReport(postId: string): Report | undefined {
  const reportStore = JSON.parse(localStorage.getItem("reports") || "{}");
  return reportStore[postId];
}

export { recordFeedback, getFeedback, recordReport, getReport };
export type { Feedback, Report };
