import React, { useState, useEffect, useCallback, useRef } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  FeedbackAction,
  QuestionAndAnswer,
  QuestionHistory,
} from "../types/models";
import { firebaseFunctions } from "../../../services/firebase";
import { httpsCallable } from "firebase/functions";
import QuestionAnswerUnit from "./QuestionAnswerUnit";
import { Box } from "@mui/material";
import {
  Feedback,
  Report,
  getFeedback,
  getReport,
  recordFeedback,
  recordReport,
} from "../services/feedbackStorage";
import AsciiSpinner from "../../../components/AsciiSpinner";

type Props =
  | {
      feedType: "new" | "top";
    }
  | {
      feedType: "single";
      postId: string;
    };

export interface GetPostsResult {
  posts: {
    id: string;
    createdAt: number;
    nanoseconds: number;
    question: string;
    answer: string;
  }[];
  paginationToken: number;
}

const LoadingWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

const QuestionFeed: React.FC<Props> = (props) => {
  const { feedType } = props;
  const [posts, setPosts] = useState<QuestionAndAnswer[]>([]);
  const [paginationToken, setPaginationToken] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const scrollerRef = useRef<HTMLDivElement | null>(null);
  const maybePostId = feedType === "single" ? props.postId : null;

  const fetchPosts = useCallback(async () => {
    const functionName =
      feedType === "new"
        ? "getLatestPosts"
        : feedType === "top"
        ? "getHotPosts"
        : "getPost";
    const getPosts = httpsCallable(firebaseFunctions, functionName);

    const fetchParams =
      feedType === "single"
        ? { postId: maybePostId }
        : {
            paginationToken,
            pageSize: 10,
          };

    try {
      const result = await getPosts(fetchParams);
      const resultData = result.data as GetPostsResult;
      const newPosts: QuestionAndAnswer[] = resultData.posts.map(
        (post: any) => ({
          id: post.id,
          postType: "question_and_answer",
          localId: post.id || "", // Use id as localId if available, otherwise generate an empty string
          question: post.question,
          answer: post.answer,
          isLoading: false,
          upvoted: getFeedback(post.id) === "upvote",
          downvoted: getFeedback(post.id) === "downvote",
          reported: getReport(post.id) === "report",
          votes: post.votes,
        })
      );

      setPosts((prevPosts) => {
        const dedupedNewPosts = newPosts.filter(
          (post) =>
            !prevPosts.some((existingPost) => existingPost.id === post.id)
        );
        return prevPosts.concat(dedupedNewPosts);
      });
      setPaginationToken(resultData.paginationToken);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [feedType, maybePostId, paginationToken]);

  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = () => {
    if (!isLoading) {
      fetchPosts();
      setIsLoading(true);
    }
  };

  const handleFeedbackClick = async (
    questionId: string,
    feedbackAction: FeedbackAction
  ) => {
    let update: Partial<QuestionHistory[number]> = {};
    const post = posts.find((post) => post.id === questionId);
    const votes = post?.votes || 0;
    let feedbackToStore: Feedback | undefined = undefined;
    let updateFeedback = false;
    let reportToStore: Report | undefined = undefined;
    let updateReport = false;

    switch (feedbackAction) {
      case "add_upvote": {
        update = { upvoted: true, downvoted: false, votes: votes + 1 };
        feedbackToStore = "upvote";
        updateFeedback = true;
        break;
      }
      case "remove_upvote": {
        update = { upvoted: false, votes: votes - 1 };
        updateFeedback = true;
        break;
      }
      case "add_downvote": {
        update = { downvoted: true, upvoted: false, votes: votes - 1 };
        feedbackToStore = "downvote";
        updateFeedback = true;
        break;
      }
      case "remove_downvote": {
        update = { downvoted: false, votes: votes + 1 };
        updateFeedback = true;
        break;
      }
      case "add_report": {
        update = { reported: true };
        reportToStore = "report";
        updateReport = true;
        break;
      }
      case "remove_report": {
        update = { reported: false };
        updateReport = true;
        break;
      }
    }

    if (updateFeedback) {
      recordFeedback(questionId, feedbackToStore);
    }
    if (updateReport) {
      recordReport(questionId, reportToStore);
    }

    setPosts((posts) =>
      posts.map((post) =>
        post.id === questionId
          ? {
              ...post,
              ...update,
            }
          : post
      )
    );
    const sendFeedback = httpsCallable(firebaseFunctions, "handleFeedback");
    await sendFeedback({
      threadId: questionId,
      action: feedbackAction,
    });
  };

  return (
    <>
      {isLoading && posts.length === 0 ? (
        <LoadingWrapper>
          <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
            <AsciiSpinner />
          </Box>
        </LoadingWrapper>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 48px)",
          }}
        >
          <Box
            ref={scrollerRef}
            sx={{
              height: "100%",
            }}
            id="scrollableDiv"
          >
            <InfiniteScroll
              dataLength={posts.length}
              next={handleLoadMore}
              hasMore={feedType !== "single"}
              loader={
                <Box
                  sx={{
                    marginTop: 8,
                    display: "flex",
                    justifyContent: "center",
                    py: 2,
                  }}
                >
                  <AsciiSpinner />
                </Box>
              }
              height={"100%"}
            >
              <Stack spacing={2}>
                {posts.map((post) => (
                  <QuestionAnswerUnit
                    key={post.id}
                    id={post.id || ""}
                    question={post.question}
                    answer={post.answer}
                    isLoading={false}
                    showRegen={false}
                    showVotes={true}
                    upvoted={!!post.upvoted}
                    downvoted={!!post.downvoted}
                    reported={!!post.reported}
                    votes={post.votes || 0}
                    onRegenClick={() => {}}
                    onFeedbackClick={(feedbackAction) =>
                      handleFeedbackClick(
                        post.id || "<MISSING>",
                        feedbackAction
                      )
                    }
                  />
                ))}
              </Stack>
            </InfiniteScroll>
          </Box>
        </Box>
      )}
    </>
  );
};

export default QuestionFeed;
