import React, { useRef, useState } from "react";
import { Box, TextField, Button, useMediaQuery, useTheme } from "@mui/material";
import "@fontsource/source-code-pro";
import rayFloatingHead from "../../image/components/ray-floating-head.png";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { getPlaceholderQuestion } from "./placeholderContent";
type Props = {
  onMessageSubmit: (message: string) => void;
};

const ChatInput = ({ onMessageSubmit }: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [message, setMessage] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !event.shiftKey && message.trim() !== "") {
      handleSubmit();
      event.preventDefault();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSubmit = () => {
    if (message.trim() !== "") {
      inputRef.current?.blur();
      onMessageSubmit(message.trim());
      setMessage("");
    }
  };

  const handleFillAutoquestion = () => {
    setMessage(getPlaceholderQuestion());
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#eaeaea",
        p: 1,
        borderTop: "2px solid black",
      }}
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: 2,
          px: 1,
          py: 0.5,
          width: "100%",
        }}
      >
        <TextField
          variant="standard"
          placeholder="Ask Ray a new question..."
          fullWidth
          multiline
          value={message}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          inputRef={inputRef}
          sx={{ mr: 1, fontFamily: "Lucida Console, Source Code Pro" }}
          InputProps={{
            disableUnderline: true,
            sx: {
              fontSize: "16px",
              fontFamily: "Lucida Console, Source Code Pro",
            },
          }}
          minRows={2}
          maxRows={8}
        />
        <Box
          sx={{
            mt: "auto",
            display: "flex",
            mb: 1,
          }}
        >
          <Button
            sx={{
              height: "40px",
              display: isSmallScreen && message.length > 0 ? "none" : "block",
            }}
            onClick={handleFillAutoquestion}
          >
            <RefreshIcon />
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            sx={{
              height: "40px",
              textTransform: "none",
              fontFamily: "Lucida Console, Source Code Pro",
            }}
            disabled={!message.length}
          >
            Send
          </Button>
        </Box>
      </Box>
      <img
        src={rayFloatingHead}
        alt=""
        style={{
          alignSelf: "flex-end",
          marginLeft: "12px",
          width: "80px",
          height: "80px",
        }}
      />
    </Box>
  );
};

export default ChatInput;
