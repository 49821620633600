import {
  Box,
  BoxProps,
  Button,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps & BoxProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

type TermsOfServiceProps = {
  onClose: () => void;
};
function TermsOfService({ onClose }: TermsOfServiceProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isSmallScreen ? 300 : 500,
        height: "90%",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        paddingBottom: 0,
      }}
    >
      <Tabs value={value} onChange={handleChange} centered>
        <Tab label="Terms of Service" />
        <Tab label="Privacy Policy" />
      </Tabs>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "0",
          right: "0",
        }}
      >
        <Button onClick={onClose}>Close</Button>
      </Box>
      <TabPanel sx={{ height: "90%", width: "100%" }} value={value} index={0}>
        <iframe
          style={{ height: "100%", width: "100%" }}
          title="Terms of Service"
          src="/termsofservice.html"
        />
      </TabPanel>
      <TabPanel sx={{ height: "90%", width: "100%" }} value={value} index={1}>
        <iframe
          style={{ height: "100%", width: "100%" }}
          title="Privacy Policy"
          src="/privacypolicy.html"
        />
      </TabPanel>
    </Box>
  );
}

export default TermsOfService;
