import { useState } from "react";
import { useInterval } from "usehooks-ts";

const _shark = [
  "▐|\\____________▌",
  "▐_|\\___________▌",
  "▐__|\\__________▌",
  "▐___|\\_________▌",
  "▐____|\\________▌",
  "▐_____|\\_______▌",
  "▐______|\\______▌",
  "▐_______|\\_____▌",
  "▐________|\\____▌",
  "▐_________|\\___▌",
  "▐__________|\\__▌",
  "▐___________|\\_▌",
  "▐____________|\\▌",
  "▐____________/|▌",
  "▐___________/|_▌",
  "▐__________/|__▌",
  "▐_________/|___▌",
  "▐________/|____▌",
  "▐_______/|_____▌",
  "▐______/|______▌",
  "▐_____/|_______▌",
  "▐____/|________▌",
  "▐___/|_________▌",
  "▐__/|__________▌",
  "▐_/|___________▌",
  "▐/|____________▌",
];

const rod = ["|", "/", "-", "\\"];

const frames = false ? _shark : rod;

type AsciiSpinnerProps = {
  color?: string;
};
const AsciiSpinner: React.FC<AsciiSpinnerProps> = ({ color = "#757575" }) => {
  const [frame, setFrame] = useState(0);
  useInterval(() => {
    setFrame((frame + 1) % frames.length);
  }, 100);

  return (
    <div
      style={{
        color,
        fontFamily: "Lucida Console, Source Code Pro",
        fontSize: 24,
      }}
    >
      {frames[frame]}
    </div>
  );
};

export default AsciiSpinner;
