import { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { FeedbackAction, QuestionHistory } from "../types/models";
import ChatInput from "./ChatInput";
import QuestionAnswerUnit from "./QuestionAnswerUnit";

type Props = {
  questionHistory: QuestionHistory;
  onMessageSubmit: (message: string) => void;
  onRegenClick: (threadId: string) => void;
  onFeedbackClick: (threadId: string, feedbackAction: FeedbackAction) => void;
};

const Chat = ({
  questionHistory,
  onMessageSubmit,
  onRegenClick,
  onFeedbackClick,
}: Props) => {
  const historyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    historyRef.current &&
      historyRef.current.scrollTo({
        top: historyRef.current.scrollHeight,
        behavior: "smooth",
      });
  }, [questionHistory]);

  const handleNewMessageSubmit = (message: string) => {
    if (message.trim() !== "") {
      onMessageSubmit(message.trim());
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 48px)",
      }}
    >
      <ChatInput onMessageSubmit={handleNewMessageSubmit} />

      <Box
        ref={historyRef}
        sx={{
          flex: "1 1 90%",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        {questionHistory.map((questionAndAnswer) => (
          <QuestionAnswerUnit
            key={questionAndAnswer.id}
            id={questionAndAnswer.id || ""}
            question={questionAndAnswer.question}
            answer={questionAndAnswer.answer}
            isLoading={questionAndAnswer.isLoading}
            // showRegen={questionAndAnswer.postType === "usage_limit_exceeded"}
            showRegen={true}
            showVotes={false}
            upvoted={!!questionAndAnswer.upvoted}
            downvoted={!!questionAndAnswer.downvoted}
            reported={!!questionAndAnswer.reported}
            votes={0}
            onRegenClick={() => onRegenClick(questionAndAnswer.id || "")}
            onFeedbackClick={(feedbackAction) =>
              onFeedbackClick(questionAndAnswer.id || "", feedbackAction)
            }
          />
        ))}
      </Box>
    </Box>
  );
};

export default Chat;
