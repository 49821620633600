import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CreateImagePage from "./features/image/pages/CreateImagePage";
import ChatPage from "./features/chat/pages/ChatPage";
import { useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import Cookies from "js-cookie";
import { initializeAnalytics } from "./services/firebase";
import CookieConsent from "react-cookie-consent";
import SettingsPage from "./features/settings/pages/SettingsPage";
import FeedPage from "./features/chat/pages/FeedPage";
import SpinnerPage from "./features/image/pages/SpinnerPage";
import AboutPage from "./features/settings/pages/AboutPage";
import PostPage from "./features/chat/pages/PostPage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#eaeaea",
    },
    success: {
      main: "#eab676",
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: "none",
      fontFamily: "Lucida Console, Source Code Pro",
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <ChatPage />,
  },
  {
    path: "/image",
    element: <CreateImagePage />,
  },
  {
    path: "/spinner",
    element: <SpinnerPage />,
  },
  {
    path: "/raybot",
    element: <ChatPage />,
  },
  {
    path: "/new",
    element: (
      <div>
        <FeedPage feedType="new" />
      </div>
    ),
  },
  {
    path: "/q/:postId",
    element: <PostPage />,
  },
  {
    path: "/top",
    element: <FeedPage feedType="top" />,
  },
  {
    path: "/settings",
    element: <SettingsPage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/chat",
    element: (
      <div>
        RayBot is down for maintenance. If you were in the original contact
        group, please request the new link via your original DM.
      </div>
    ),
  },
]);

const App = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const hasConsent = Cookies.get("cookieConsent") === "true";

    if (hasConsent) {
      initializeAnalytics();
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      // Disable scrolling on mobile devices
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    }
  }, [isMobile]);
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          autoHideDuration={1000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <RouterProvider router={router} />
          <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="I understand"
            cookieName="cookieConsent"
            style={{ background: "#eaeaea", color: "#000" }}
            buttonStyle={{
              backgroundColor: "#eab676",
              color: "black",
              fontSize: "13px",
            }}
            expires={150}
            onAccept={() => {
              initializeAnalytics();
            }}
          >
            This website uses cookies to enhance the user experience.
          </CookieConsent>
        </SnackbarProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
