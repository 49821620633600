export const placeholderQuestions = [
  "Can you please write a sentence that has never existed in English before?",
  "I'm starting a band. Can you please list three good band names?",
  "I have a free afternoon. What's one fun thing I should do?",
  "I have a crush on my barista. How can I get noticed?",
  "If someone uses Comic Sans font, are they a bad person?",
  "Should we forgive our parents for their flaws?",
  "Please name one thing that would have been in Benjamin Franklin's stomach.",
  "What is one thing we can learn from our grandparents?",
  "Why is modern clothing so uninspired?",
  "Did food used to taste better than it does now?",
  "What's one aspect of humanity that can unite all humans?",
  "What's a good breakfast for recovering from a hangover?",
  "What's the best song to make love to?",
  "What's the best Spotify playlist to make love to?",
  "What's something sexy and new I can try in the bedroom?",
  "What heroic qualities does a person need in the modern day?",
  "Is it rad to have alcoholism?",
];

export function getPlaceholderQuestion() {
  return placeholderQuestions[
    Math.floor(Math.random() * placeholderQuestions.length)
  ];
}

export const placeholderNames = [
  "Confounded Colin",
  "Perplexed Penelope",
  "Bewildered Bill",
  "Flummoxed Francine",
  "Baffled Bertie",
  "Discombobulated Delilah",
  "Mystified Max",
  "Confused Connie",
  "Puzzled Percy",
  "Stumped Stella",
  "Wally the Worried Walrus",
  "Patty the Panicking Penguin",
  "Gus the Grouchy Giraffe",
  "Lenny the Lost Llama",
  "Fanny the Frazzled Flamingo",
  "Randy the Reckless Rhinoceros",
  "Sandy the Scatterbrained Squirrel",
  "Benny the Baffled Bison",
  "Mandy the Misguided Monkey",
  "Harry the Hapless Hedgehog",
];

export function getPlaceholderName() {
  return placeholderNames[Math.floor(Math.random() * placeholderNames.length)];
}
