import { Box, Link } from "@mui/material";
import RayAdviceImage from "../../image/components/RayAdviceImage";

type ShareModalProps = {
  question: string;
  answer: string;
  onClose: () => void;
};
function ShareModal({ question, answer, onClose }: ShareModalProps) {
  return (
    <Box
      sx={{
        position: "relative",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "300px",
        height: "300px",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        padding: 0,
      }}
    >
      <Box
        sx={{
          fontFamily: "Courier New",
          position: "absolute",
          top: "4px",
          right: "8px",
        }}
      >
        <Link
          onClick={onClose}
          sx={{
            textDecoration: "none",
          }}
        >
          X
        </Link>
      </Box>
      <RayAdviceImage question={question} answer={answer} />
    </Box>
  );
}

export default ShareModal;
