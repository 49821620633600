import AsciiSpinner from "../../../components/AsciiSpinner";

function SpinnerPage() {
  return (
    <div>
      <AsciiSpinner />
    </div>
  );
}

export default SpinnerPage;
