import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import rayReadyImage from "../../../assets/rayready.png";
import ClearIcon from "@mui/icons-material/Clear";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getPlaceholderQuestion } from "./placeholderContent";

type InitialQuestionProps = {
  userName: string;
  initialQuestion?: string;
  onSubmitQuestion: (question: string) => void;
};
const InitialQuestion = ({
  userName,
  initialQuestion,
  onSubmitQuestion,
}: InitialQuestionProps) => {
  const [question, setQuestion] = useState(initialQuestion || "");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !event.shiftKey && question.trim() !== "") {
      handleSubmit();
      event.preventDefault();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = () => {
    if (question.trim() !== "") {
      onSubmitQuestion(question.trim());
    }
  };

  const handleClear = () => {
    setQuestion("");
    inputRef.current?.focus();
  };

  const handleFillAutoquestion = () => {
    setQuestion(getPlaceholderQuestion());
    inputRef.current?.blur();
  };

  return (
    <Stack spacing={2}>
      <Stack
        spacing={2}
        paddingLeft={2}
        paddingTop={1}
        direction="row"
        alignItems="flex-end"
        alignContent="center"
        justifyContent="middle"
      >
        <Typography
          sx={{
            marginBottom: 3,
            fontFamily: "Lucida Console, Source Code Pro",
          }}
        >
          Hey {userName}! Ray here. What can I help you with?
        </Typography>
        <img
          src={rayReadyImage}
          alt=""
          style={{
            width: 140,
            height: 140,
          }}
        />
      </Stack>
      <Divider style={{ margin: 0 }} />
      <Stack paddingX={4} spacing={2}>
        <Box
          sx={{
            display: "flex",
            backgroundColor: "white",
            border: "2px solid black",
            borderRadius: 2,
            px: 1,
            py: 0.5,
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <TextField
            variant="standard"
            placeholder="Enter your question"
            multiline
            fullWidth
            value={question}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
            sx={{
              mr: 1,
              fontFamily: "Lucida Console, Source Code Pro",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Stack>
                    <IconButton edge="end" onClick={handleFillAutoquestion}>
                      <RefreshIcon />
                    </IconButton>
                    <IconButton edge="end" onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  </Stack>
                </InputAdornment>
              ),
              disableUnderline: true,
              sx: {
                fontSize: "16px",
                fontFamily: "Lucida Console, Source Code Pro",
              },
            }}
            minRows={4}
            maxRows={12}
          />
        </Box>
        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          disabled={question.length === 0}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

export default InitialQuestion;
