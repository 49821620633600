import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import RayAdviceImage from "../components/RayAdviceImage";
import raySaysTemplate from "./ray-says-template.png";

const image = new Image();
image.src = raySaysTemplate;

export default function CreateImagePage() {
  const [questionText, setQuestionText] = useState(
    "How can I spice up my personal outfits each day in a way that is neat but isn't too big of a change? —Jason"
  );
  const [answerText, setAnswerText] = useState(
    `Dear Jason,
    As I have said before, the key to being fresh yet familiar each day is to change just one item. If you’re in a T-shirt and jeans, try a different kind of T-shirt or different color jeans. Maybe take an Irish national scarf instead of a collared shirt. Get a new watch, find a different-colored piece of gum, that kind of thing.
    Thanks for writing. I hope this helps.`
  );

  return (
    <Box sx={{ width: "600px" }}>
      <RayAdviceImage question={questionText} answer={answerText} />
      <Box>
        <TextField
          multiline
          value={questionText}
          onChange={(e) => setQuestionText(e.target.value)}
        />{" "}
        <TextField
          multiline
          value={answerText}
          onChange={(e) => setAnswerText(e.target.value)}
        />
      </Box>
    </Box>
  );
}
