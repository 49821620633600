import { Box, Chip, Stack } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import "@fontsource/source-code-pro";
import rayFloatingHead from "./ray-floating-head.png";
import * as htmlToImage from "html-to-image";
import { Textfit } from "react-textfit";
import React from "react";
import { enqueueSnackbar } from "notistack";

let globalBlob: Blob | null = null;

const rayFloatingHeadImage = new Image();
rayFloatingHeadImage.src = rayFloatingHead;

type RayAdviceImageProps = {
  question: string;
  answer: string;
};
const RayAdviceImage = ({ question, answer }: RayAdviceImageProps) => {
  const sourceRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const [questionReady, setQuestionReady] = useState(false);
  const [answerReady, setAnswerReady] = useState(false);
  const [canShare, setCanShare] = useState(false);
  const [readyToRender, setReadyToRender] = useState(false);

  const generateImage = useCallback(async () => {
    if (!sourceRef.current || !imgRef.current || !downloadLinkRef.current) {
      return;
    }

    try {
      const dataUrl = await htmlToImage.toPng(sourceRef.current);
      globalBlob = await htmlToImage.toBlob(sourceRef.current);
      // console.log(dataUrl);
      imgRef.current.src = dataUrl;
      downloadLinkRef.current.href = dataUrl;
      downloadLinkRef.current.download = "raybot-says.png";
      setReadyToRender(true);

      if (!canShare && globalBlob) {
        let file = new File([globalBlob], "raybot-says.png", {
          type: "image/png",
        });
        let files = [file];
        setCanShare(navigator.canShare({ files }));
      }
    } catch (err) {
      console.error("Error generating image:", err);
    }
  }, [canShare]);

  const handleDownload = () => {
    downloadLinkRef.current?.click();
  };

  const handleCopy = () => {
    if (!globalBlob) return;
    navigator.clipboard
      .write([new ClipboardItem({ "image/png": globalBlob })])
      .then(() => {
        enqueueSnackbar("Copied to clipboard");
      })
      .catch((err) => {
        console.log("Failed to copy: " + err.toString());
      });
  };

  const handleShare = () => {
    if (!globalBlob) return;
    let file = new File([globalBlob], "raybot-says.png", {
      type: "image/png",
    });
    let files = [file];
    navigator.share({
      title: "",
      files: files,
    });
  };

  useEffect(() => {
    setQuestionReady(false);
    setAnswerReady(false);
  }, [question, answer]);

  useEffect(() => {
    if (questionReady && answerReady) {
      setTimeout(() => generateImage(), 100);
    }
  }, [questionReady, answerReady, generateImage]);

  const questionLines = question.split("\n");
  const answerLines = answer.split("\n");

  return (
    <Box>
      {/* The invisible div containing the HTML elements */}
      <Box sx={{ position: "absolute", left: "-99999px", top: "-9999px" }}>
        <Box
          ref={sourceRef}
          sx={{ width: "600px", height: "600px", backgroundColor: "white" }}
        >
          <Box
            sx={{
              boxSizing: "border-box",
              width: "600px",
              height: "120px",
              backgroundColor: "#3D6342",
              fontFamily: "Lucida Console, Source Code Pro",
              color: "white",
              padding: 2,
            }}
          >
            <Textfit
              mode="multi"
              min={1}
              max={54}
              onReady={() => setQuestionReady(true)}
              style={{
                width: "calc(100% - 16px)",
                height: "100%",
                fontFamily: "Lucida Console, Source Code Pro",
                color: "white",
              }}
            >
              {questionLines.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < questionLines.length - 1 && <br />}
                </React.Fragment>
              ))}
            </Textfit>
          </Box>
          <Box
            sx={{
              boxSizing: "border-box",
              width: "600px",
              height: "460px",
              padding: 2,
            }}
          >
            <Textfit
              mode="multi"
              min={1}
              max={32}
              onReady={() => setAnswerReady(true)}
              style={{
                width: "100%",
                height: "100%",
                fontFamily: "Lucida Console, Source Code Pro",
                color: "black",
              }}
            >
              <img
                src={rayFloatingHead}
                alt=""
                style={{
                  float: "left",
                }}
              />
              {answerLines.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < answerLines.length - 1 && <br />}
                </React.Fragment>
              ))}
              <br />
              <br />
              <span
                style={{
                  textAlign: "right",
                  fontFamily: "Lucida Console, Source Code Pro",
                  color: "#07458c",
                  width: "100%",
                }}
              >
                -Get today's best advice at RayBot.help
              </span>
            </Textfit>
          </Box>
        </Box>
      </Box>
      <img
        ref={imgRef}
        alt={`RayBot says: ${question} followed by ${answer}`}
        width="300px"
        height="300px"
        style={{
          width: "300px",
          height: "300px",
          visibility:
            readyToRender && questionReady && answerReady
              ? "visible"
              : "hidden",
        }}
      />

      {/* The link to download the image */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a ref={downloadLinkRef} style={{ display: "none" }}>
        Download Image
      </a>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={2}
        paddingY={0.5}
        sx={{
          backgroundColor: "#afc772",
        }}
      >
        <Chip
          label="Copy"
          onClick={handleCopy}
          sx={{
            backgroundColor: "#eed870",
            color: "black",
            fontFamily: "Lucida Console, Source Code Pro",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        />
        <Chip
          label="Download"
          onClick={handleDownload}
          sx={{
            backgroundColor: "#eed870",
            color: "black",
            fontFamily: "Lucida Console, Source Code Pro",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        />
        {canShare && (
          <Chip
            label="Share"
            onClick={handleShare}
            sx={{
              backgroundColor: "#eed870",
              color: "black",
              fontFamily: "Lucida Console, Source Code Pro",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          />
        )}
      </Stack>
    </Box>
  );
};

export default RayAdviceImage;
