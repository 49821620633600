import { useState } from "react";
import { Box, Link, Modal } from "@mui/material";
import { Chat } from "../components";
import { FeedbackAction, QuestionHistory } from "../types/models";
import { firebaseFunctions } from "../../../services/firebase";
import { httpsCallable } from "firebase/functions";
import { v4 as uuidv4 } from "uuid";
import {
  getPlaceholderName,
  getPlaceholderQuestion,
} from "../components/placeholderContent";
import Intro from "../components/Intro";
import InitialQuestion from "../components/InitialQuestion";
import rayFloatingHead from "../../image/components/ray-floating-head.png";
import TermsOfService from "../components/TermsOfService";
import Consent from "../components/Consent";
import AppContainer from "../../../components/AppContainer";
import useLocalStorage from "../../../hooks/useLocalStorage";
import {
  Feedback,
  Report,
  recordFeedback,
  recordReport,
} from "../services/feedbackStorage";

interface HandleChatResponse {
  status: "success" | "usage_limit_exceeded";
  id: string;
  responseText: string;
}

const placeholderQuestions: QuestionHistory = [
  {
    postType: "question_and_answer",
    id: "1",
    localId: "1",
    question: "What is the meaning of life?",
    answer: "42",
    isLoading: false,
  },
];
const usePlaceholders = false;

const ChatPage = () => {
  type FlowStates = "consent" | "queryName" | "initialQuestion" | "chat";
  const [hasAgreedToTerms, setHasAgreedToTerms] = useLocalStorage<boolean>(
    "hasAgreedToTerms",
    false
  );
  const [flowState, setFlowState] = useState<FlowStates>(
    hasAgreedToTerms ? "queryName" : "consent"
  );
  const [userName, setUserName] = useState<string>(getPlaceholderName());
  const [questionHistory, setQuestionHistory] = useState<QuestionHistory>(
    usePlaceholders ? placeholderQuestions : []
  );
  const [showTerms, setShowTerms] = useState(false);

  const handleAgreeConsent = () => {
    setHasAgreedToTerms(true);
    setFlowState("queryName");
  };

  const handleSetUsername = (name: string) => {
    setUserName(name);
    setFlowState("initialQuestion");
  };

  const handleSetInitialQuestion = (question: string) => {
    setFlowState("chat");
    handleNewMessageSubmit(question);
  };

  const handleNewMessageSubmit = async (message: string) => {
    const localId = uuidv4();
    const formattedQuestion = message + " —" + userName;

    setQuestionHistory((questionHistory) => [
      ...questionHistory,
      {
        postType: "question_and_answer",
        localId,
        question: formattedQuestion,
        answer: "",
        isLoading: true,
      },
    ]);

    try {
      const sendMessage = httpsCallable(firebaseFunctions, "handleChatMessage");
      const result = await sendMessage({
        message: formattedQuestion,
        username: userName,
      });
      const response: HandleChatResponse = result.data as HandleChatResponse;

      if (response.status === "usage_limit_exceeded") {
        setQuestionHistory((questionHistory) =>
          questionHistory.map((qa) =>
            qa.localId === localId
              ? {
                  ...qa,
                  id: uuidv4(),
                  answer:
                    "Sorry, I'm out of API calls for the moment. You can visit Hot questions in the menu above.",
                  isLoading: false,
                }
              : qa
          )
        );
      }

      setQuestionHistory((questionHistory) =>
        questionHistory.map((qa) =>
          qa.localId === localId
            ? {
                ...qa,
                id: response.id,
                answer: response.responseText,
                isLoading: false,
              }
            : qa
        )
      );
    } catch (err: any) {
      setQuestionHistory((questionHistory) =>
        questionHistory.map((qa) =>
          qa.localId === localId
            ? {
                ...qa,
                id: uuidv4(),
                answer:
                  "Sorry, I'm experiencing a heavy load right now (snicker). Try again in a moment, or visit Hot questions in the menu above.",
                isLoading: false,
              }
            : qa
        )
      );
    }
  };

  const handleRegenClick = async (questionId: string) => {
    const message = questionHistory.find(
      (qa) => qa.id === questionId
    )?.question;

    setQuestionHistory((questionHistory) =>
      questionHistory.map((qa) =>
        qa.id === questionId ? { ...qa, answer: "", isLoading: true } : qa
      )
    );

    const sendMessage = httpsCallable(firebaseFunctions, "handleChatMessage");
    const result = await sendMessage({ message, username: userName });
    const response: HandleChatResponse = result.data as HandleChatResponse;

    setQuestionHistory((questionHistory) =>
      questionHistory.map((qa) =>
        qa.id === questionId
          ? {
              ...qa,
              id: response.id,
              answer: response.responseText,
              isLoading: false,
              upvoted: false,
              downvoted: false,
              reported: false,
            }
          : qa
      )
    );
  };

  const handleFeedbackClick = async (
    questionId: string,
    feedbackAction: FeedbackAction
  ) => {
    let update: Partial<QuestionHistory[number]> = {};
    let feedbackToStore: Feedback | undefined = undefined;
    let updateFeedback = false;
    let reportToStore: Report | undefined = undefined;
    let updateReport = false;

    switch (feedbackAction) {
      case "add_upvote": {
        update = { upvoted: true, downvoted: false };
        feedbackToStore = "upvote";
        updateFeedback = true;
        break;
      }
      case "remove_upvote": {
        update = { upvoted: false };
        updateFeedback = true;
        break;
      }
      case "add_downvote": {
        update = { downvoted: true, upvoted: false };
        feedbackToStore = "downvote";
        updateFeedback = true;
        break;
      }
      case "remove_downvote": {
        update = { downvoted: false };
        updateFeedback = true;
        break;
      }
      case "add_report": {
        update = { reported: true };
        reportToStore = "report";
        updateReport = true;
        break;
      }
      case "remove_report": {
        update = { reported: false };
        updateReport = true;
        break;
      }
    }

    if (updateFeedback) {
      recordFeedback(questionId, feedbackToStore);
    }
    if (updateReport) {
      recordReport(questionId, reportToStore);
    }

    setQuestionHistory((questionHistory) =>
      questionHistory.map((qa) =>
        qa.id === questionId
          ? {
              ...qa,
              ...update,
            }
          : qa
      )
    );
    const sendFeedback = httpsCallable(firebaseFunctions, "handleFeedback");
    await sendFeedback({
      threadId: questionId,
      action: feedbackAction,
    });
  };

  return (
    <AppContainer>
      {/* hack: force loading of the image used in drawing the share image */}
      <img src={rayFloatingHead} alt="" style={{ display: "none" }} />

      {flowState === "consent" && (
        <Consent
          onAgree={handleAgreeConsent}
          onClickTerms={() => setShowTerms(true)}
        />
      )}
      {flowState === "queryName" && <Intro onSubmitName={handleSetUsername} />}
      {flowState === "initialQuestion" && (
        <InitialQuestion
          userName={userName}
          initialQuestion={getPlaceholderQuestion()}
          onSubmitQuestion={handleSetInitialQuestion}
        />
      )}
      {flowState === "chat" && (
        <Chat
          questionHistory={questionHistory}
          onMessageSubmit={handleNewMessageSubmit}
          onRegenClick={handleRegenClick}
          onFeedbackClick={handleFeedbackClick}
        />
      )}
      {false && (
        <Box
          sx={{
            height: "50px",
            backgroundColor: "black",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: "0px",
            fontFamily: "Lucida Console, Source Code Pro",
            textAlign: "center",
            justifyContent: "center",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Link
            sx={{
              color: "white",
              fontFamily: "Lucida Console, Source Code Pro",
              cursor: "pointer",
              textDecoration: "none",
            }}
            onClick={() => setShowTerms(true)}
          >
            Terms of Service and Privacy Policy
          </Link>
        </Box>
      )}
      <Modal open={showTerms}>
        <TermsOfService onClose={() => setShowTerms(false)} />
      </Modal>
    </AppContainer>
  );
};

export default ChatPage;
