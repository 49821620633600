import {
  Box,
  IconButton,
  IconButtonProps,
  Link,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import {
  Refresh as RefreshIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  IosShare as ShareIcon,
  ContentCopy as ContentCopyIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  Flag as ReportIcon,
  Link as LinkIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useSpring, animated } from "react-spring";
import { useState } from "react";
import { FeedbackAction } from "../types/models";
import ShareModal from "./ShareModal";
import { useSnackbar } from "notistack";
import AsciiSpinner from "../../../components/AsciiSpinner";

const QuestionAnswerContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  padding: "1rem",
});

const QuestionUnit = styled("div")({
  backgroundColor: "#F7F7F7",
  padding: "0.5rem",
  fontFamily: "Lucida Console, Source Code Pro",
});

const AnswerUnit = styled("div")({
  backgroundColor: "#FFFFFF",
  padding: "0.5rem",
  fontFamily: "Lucida Console, Source Code Pro",
});

const Toolbox = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  fontFamily: "Lucida Console, Source Code Pro",
});

const ToolboxLeft = styled("div")({
  display: "flex",
  alignItems: "center",
});

const ToolboxRight = styled("div")({
  display: "flex",
  alignItems: "center",
});

const ToolboxIcon = styled(IconButton)({
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const BouncyToolbarIcon = ({ children, ...props }: IconButtonProps) => {
  const [clicked, setClicked] = useState(false);

  const spring = useSpring({
    to: {
      transform: clicked ? "scale(0.8)" : "scale(1)",
    },
    config: {
      mass: 1,
      tension: 500,
      friction: 30,
    },
  });

  return (
    <animated.span style={spring}>
      <ToolboxIcon
        onTouchStart={() => setClicked(true)}
        onTouchEnd={() => setClicked(false)}
        onTouchCancel={() => setClicked(false)}
        onMouseDown={() => setClicked(true)}
        onMouseUp={() => setClicked(false)}
        onMouseLeave={() => setClicked(false)}
        {...props}
      >
        {children}
      </ToolboxIcon>
    </animated.span>
  );
};

type QuestionAnswerUnitProps = {
  id: string;
  question: string;
  answer: string;
  isLoading: boolean;
  showRegen: boolean;
  showVotes: boolean;
  upvoted: boolean;
  downvoted: boolean;
  reported: boolean;
  votes: number;
  onRegenClick: () => void;
  onFeedbackClick: (feedbackAction: FeedbackAction) => void;
};
const QuestionAnswerUnit = ({
  id,
  question,
  answer,
  isLoading,
  showRegen,
  showVotes,
  upvoted,
  downvoted,
  reported,
  votes,
  onRegenClick,
  onFeedbackClick,
}: QuestionAnswerUnitProps) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showSocials = false;
  const showDownloadImage = true;

  const handleCopyClick = () => {
    const wholeText = `Ray,\n\n${question}\n\n—\n\n${answer}\n\nFrom RayBot.help`;
    navigator.clipboard.writeText(wholeText);
    enqueueSnackbar("Text copied to clipboard");
  };

  return (
    <QuestionAnswerContainer key={question}>
      <QuestionUnit>
        {question.split("\n").map((line, index) => (
          <Box>{line}</Box>
        ))}
      </QuestionUnit>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
          <AsciiSpinner color="#757575" />
        </Box>
      )}
      {answer && answer.length > 0 && (
        <>
          <AnswerUnit>
            <Stack spacing={1}>
              {answer.split("\n").map((line, index) => (
                <Box>{line}</Box>
              ))}
            </Stack>
          </AnswerUnit>
          <Toolbox>
            <ToolboxLeft>
              {showRegen && (
                <BouncyToolbarIcon
                  onClick={onRegenClick}
                  aria-label="Regenerate"
                >
                  <Typography
                    component="span"
                    sx={{ fontFamily: "Lucida Console, Source Code Pro" }}
                  >
                    REGEN
                  </Typography>
                  <RefreshIcon />
                </BouncyToolbarIcon>
              )}
              <BouncyToolbarIcon
                title="Vote Up"
                color={upvoted ? "success" : "default"}
                onClick={() =>
                  onFeedbackClick(upvoted ? "remove_upvote" : "add_upvote")
                }
              >
                <ThumbUpIcon aria-label="Vote Up" />
              </BouncyToolbarIcon>
              <BouncyToolbarIcon
                title="Vote Down"
                color={downvoted ? "success" : "default"}
                onClick={() =>
                  onFeedbackClick(
                    downvoted ? "remove_downvote" : "add_downvote"
                  )
                }
              >
                <ThumbDownIcon aria-label="Vote Down" />
              </BouncyToolbarIcon>
              {showVotes && (
                <Typography
                  component="span"
                  sx={{
                    fontFamily: "Lucida Console, Source Code Pro",
                    marginBottom: "4px",
                  }}
                >
                  ({(votes < 0 ? "-" : "+") + Math.abs(votes).toString()})
                </Typography>
              )}
            </ToolboxLeft>
            <ToolboxRight>
              {showDownloadImage && (
                <BouncyToolbarIcon
                  title="Save as Image"
                  onClick={() => setShareModalOpen(true)}
                >
                  <ShareIcon aria-label="Save as Image" />
                </BouncyToolbarIcon>
              )}
              <BouncyToolbarIcon onClick={handleCopyClick} title="Copy Text">
                <ContentCopyIcon aria-label="Copy Text" />
              </BouncyToolbarIcon>
              <BouncyToolbarIcon title="Permalink">
                <Link href={`/q/${id}`}>
                  <LinkIcon aria-label="Permalink" />
                </Link>
              </BouncyToolbarIcon>
              <BouncyToolbarIcon
                onClick={() =>
                  onFeedbackClick(reported ? "remove_report" : "add_report")
                }
                color={reported ? "success" : "default"}
                title="Report"
              >
                <ReportIcon aria-label="Report" />
              </BouncyToolbarIcon>
              {showSocials && (
                <>
                  <BouncyToolbarIcon title="Share to Instagram">
                    <InstagramIcon aria-label="Share to Instagram" />
                  </BouncyToolbarIcon>
                  <BouncyToolbarIcon title="Share to Twitter">
                    <TwitterIcon aria-label="Share to Twitter" />
                  </BouncyToolbarIcon>
                  <BouncyToolbarIcon title="Share to Facebook">
                    <FacebookIcon aria-label="Share to Facebook" />
                  </BouncyToolbarIcon>
                </>
              )}
            </ToolboxRight>
          </Toolbox>
        </>
      )}
      <Modal open={shareModalOpen} onClose={() => setShareModalOpen(false)}>
        <ShareModal
          question={question}
          answer={answer}
          onClose={() => setShareModalOpen(false)}
        />
      </Modal>
    </QuestionAnswerContainer>
  );
};

export default QuestionAnswerUnit;
